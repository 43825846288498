import React, { Component } from "react";
import { Underlines } from '../Underlines/Underlines';
import Slider from "react-slick";
import Stats from './data'

import "./stats.less" 


export default class MultipleItems extends Component {
  render() {
    return (
        <section className="stats" id="cases">
            <Underlines/>
            <h1>Nuestros clientes reciben victorias, no excusas</h1>
            <div className="stats-wrapper">
                <div className="stats-carousel sm">
                    <Slider 
                        swipeToSlide={true}
                        slidesToShow={1}
                        focusOnSelect={true}
                        autoplay={true}
                        autoplaySpeed={6000}
                        lazyLoad={true}
                        cssEase={"ease-in-out"}
                    >
                        {
                            Stats.map(({practice, icon, winnings})=>{
                                return(
                                    <div className="single-stat-card">
                                        <p className="practice">{practice}</p>
                                        <img src={icon} alt="" />
                                        <p className="what-we-won">Lo que ganamos</p>
                                        <h3 className="winnings">{winnings}</h3>
                                    </div>
                                )
                            })
                        }
                    </Slider>    
                </div>
                <div className="stats-carousel md">
                    <Slider 
                        swipeToSlide={true}
                        slidesToShow={2}
                        focusOnSelect={true}
                        autoplay={true}
                        autoplaySpeed={6000}
                        lazyLoad={true}
                        cssEase={"ease-in-out"}
                    >
                        {
                            Stats.map(({practice, icon, winnings})=>{
                                return(
                                    <div className="single-stat-card">
                                        <p className="practice">{practice}</p>
                                        <img src={icon} alt="" />
                                        <p className="what-we-won">Lo que ganamos</p>
                                        <h3 className="winnings">{winnings}</h3>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
                <div className="stats-carousel lg">
                    <Slider 
                        swipeToSlide={true}
                        slidesToShow={3}
                        focusOnSelect={true}
                        autoplay={true}
                        autoplaySpeed={6000}
                        lazyLoad={true}
                        cssEase={"ease-in-out"}
                    >
                        {
                            Stats.map(({practice, icon, winnings})=>{
                                return(
                                    <div className="single-stat-card">
                                        <p className="practice">{practice}</p>
                                        <img src={icon} alt="" />
                                        <p className="what-we-won">Lo que ganamos</p>
                                        <h3 className="winnings">{winnings}</h3>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </section>
    );
  }
}
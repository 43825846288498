import React, { useState } from 'react';
import {BsFillShieldLockFill} from 'react-icons/bs'
import {IoIosLock} from 'react-icons/io'
import { Underlines, UnderlineSm } from "../Underlines/Underlines";
import Cta from '../CTA/Cta';
import Clock from '../../images/Clock.png'
import './next.less'
export default () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [injury, setInjury] = useState("");
    const [message, setMessage] = useState("");

    const logEvent = (e) => {
        console.log(e.currentTarget.id);
    }


    return (
        <section className="next" id="next">
            <Underlines/>
            <article className="next-wrapper container">
                <img src={Clock} alt="Clock" />
                <h2>¡Tu tiempo puede agotarse!</h2>
                <div className="ul-one">
                    <Underlines/>
                </div>
                <p className="yellow-text">
                    Esto es lo que debe hacer a continuación
                </p>
                <ul>
                    <li>
                        Contáctenos ahora para una consulta GRATUITA y cuéntenos su historia.
                    </li>
                    <li>
                        Si creemos que podemos ayudarlo, se le ofrecerá una cita GRATUITA con el abogado y, si es necesario, acudiremos a usted al 100% CERO costo.
                    </li>
                    <li>
                        Durante su cita, nuestro abogado podrá determinar si su reclamo tiene mérito.
                    </li>
                    <li>
                        Si es así, le ofreceremos nuestro acuerdo <span>No Win - No Fee</span>.
                    </li>
                    <li>
                        Regístrate y empezaremos a trabajar en tu caso.
                    </li>
                </ul>
                <div className="ul-two">
                    <Underlines/>
                </div>
                <a href="tel:212-365-4255" className='btn'>
                    Obtenga su consulta GRATIS ahora
                    <span>
                        212-365-4255
                    </span>
                </a>
            </article>
        </section>
    )
}